import React from "react";
import Link from "next/link";

type NavigationLinkProps = React.ComponentProps<"a">;

export const NavigationLink = ({ children, href }: NavigationLinkProps) => {
  return (
    <Link href={href} className="space-y-[4px] flex flex-col items-center">
      {children}
    </Link>
  );
};
