import { useRouter } from "next/router";
import { useEffect } from "react";
import { loginWithMidias } from "../../lib/authentication";
import { useDispatch } from "react-redux";
import { IUseLoginWithApple } from "../../interfaces/hooks/user/IUseLoginWithApple";
import useUser from "./useUser";
import * as Sentry from "@sentry/nextjs";
import { sendEmailToFirstPromoter } from "../../lib/firstPromoter/sendEmailToFp";
import { setServiceErrorMessage } from "../../redux/loginServiceSlice";

export const useLoginWithApple = ({
  setIsLoading,
  toReload = true,
}: IUseLoginWithApple) => {
  const router = useRouter();
  const { token } = router.query;
  const dispatch = useDispatch();
  const { setUser } = useUser();

  useEffect(() => {
    if (token) {
      dispatch(setIsLoading(true));
      loginWithMidias(token as string, "apple", true)
        .then((user) => {
          sendEmailToFirstPromoter(user.email);

          if (toReload) {
            void router.reload();
            return;
          }
          void router.push("/");

          location?.reload();
        })
        .catch((e: Error) => {
          console.error(e);
          dispatch(setIsLoading(false));
          dispatch(setServiceErrorMessage(e.message));

          Sentry.captureException({
            category: "useLoginWithApple",
            message: "error on loginWithMidias apple",
            data: e,
          });
        });
    } else {
      return;
    }
  }, [token, setUser, dispatch, setIsLoading, router, toReload]);
};
