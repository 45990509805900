import React from "react";
import { cn } from "../../../helpers/cn";

type StoreImageProps = React.ComponentProps<"img">;

export const StoreImage = ({ className, src, alt }: StoreImageProps) => {
  return (
    <img
      src={src}
      alt={alt}
      width={120}
      height={45}
      className={cn(`w-auto object-contain cursor-pointer `, className)}
    />
  );
};
