import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const LoginFormLoadingServiceBtns = () => {
  const theme = useSelector((state: RootState) => state.theme);
  return (
    <div className="grid grid-cols-3 gap-[16px]">
      <div
        style={{
          backgroundColor: theme.componentColors.surface.surfContainerHighest,
        }}
        className="w-full rounded-2xl h-[40px] animate-pulse"
      />
      <div
        style={{
          backgroundColor: theme.componentColors.surface.surfContainerHighest,
        }}
        className="w-full rounded-2xl h-[40px] animate-pulse"
      />
      <div
        style={{
          backgroundColor: theme.componentColors.surface.surfContainerHighest,
        }}
        className="w-full rounded-2xl h-[40px] animate-pulse"
      />
    </div>
  );
};
