import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { LoginForm } from "../../../auth/login/LoginForm";
import { DefaultModal } from "../DefaultModal";
import { setLoginModal } from "../../../../../redux/pages/loginPageSlice";

export const LoginModal = () => {
  const loginPageRedux = useSelector((state: RootState) => state.loginPage);
  const dispatch = useDispatch();

  return (
    <DefaultModal
      open={loginPageRedux.showLoginModal}
      onClose={() => {
        dispatch(setLoginModal());
      }}
      showBottomsheetOnlyForMobile
    >
      <LoginForm isModal={true} />
    </DefaultModal>
  );
};
