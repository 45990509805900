import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { IconButton } from "@mui/material";
import { changeMode } from "../../../../../redux/themeSlice";
import { DarkMode, LightMode } from "@mui/icons-material";

export const NavigationChangeTheme = () => {
  const theme = useSelector((state: RootState) => state.theme);
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col items-center space-y-[4px] place-self-end">
      <IconButton
        color="secondary"
        sx={{
          color: theme.componentColors.secondary.onSecondaryContainer,
          borderColor: theme.componentColors.secondary.onSecondaryContainer,
        }}
        className="p-[16px] border border-solid"
        onClick={() => {
          dispatch(changeMode());
        }}
      >
        {theme.mode === "dark" ? <LightMode /> : <DarkMode />}
      </IconButton>
    </div>
  );
};
