import Link from "next/link";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../redux/store";
import { useRouter } from "next/router";
import { Typography } from "@mui/material";

type NavbarLinkProps = React.ComponentProps<"a">;

export const NavbarLink = ({ children, className, href }: NavbarLinkProps) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);
  const router = useRouter();
  const hrefIsCurrentPathname = router.pathname === href;

  return (
    <Link href={href} className={className}>
      <Typography
        sx={{
          color: hrefIsCurrentPathname
            ? colors.surface.onSurface
            : colors.surface.onSurfaceVariant,
          fontWeight: hrefIsCurrentPathname ? 600 : 500,
          "&:hover": {
            color: colors.surface.onSurface,
          },
        }}
      >
        {children}
      </Typography>
    </Link>
  );
};
