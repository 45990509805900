import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { Button } from "@mui/material";
import { ICustomButton } from "../../../../../interfaces/globalComponents/buttons/material/ICustomButton";
import { cn } from "../../../../../helpers/cn";

export const PrimaryButton = ({
  title,
  className,
  onClick,
  startIcon,
  disabled,
  type = "button",
  sx,
}: ICustomButton) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Button
      type={type}
      className={cn(`rounded-3xl px-[24px] h-10 font-medium `, className)}
      onClick={() => {
        if (onClick) {
          void onClick();
        }
      }}
      startIcon={startIcon}
      disabled={disabled}
      sx={{
        textTransform: "none",
        bgcolor: theme.componentColors.primary.primary,
        color: theme.componentColors.primary.onPrimary,
        "&:hover": {
          bgcolor: theme.componentColors.primary.primary,
          color: theme.componentColors.primary.onPrimary,
        },
        "&:disabled": {
          bgcolor: theme.componentColors.outline.outlineVariant,
          color: theme.componentColors.surface.onSurface,
          opacity: 0.6,
        },
        "@media (min-width: 1240px)": {
          "&:hover": {
            bgcolor: theme.componentColors.primary.primary,
            color: theme.componentColors.primary.onPrimary,
          },
        },
        opacity: disabled ? 0.5 : 1.0,
        ...sx,
      }}
    >
      {title}
    </Button>
  );
};
