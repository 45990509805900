import Link from "next/link";
import { TextButton } from "../../../buttons/material/textButton/TextButton";
import { PrimaryButton } from "../../../buttons/material/primaryButton/PrimaryButton";

export const NavbarLoginButtons = () => {
  return (
    <div className="flex items-center justify-center">
      <Link href="/login">
        <TextButton text="Log In" />
      </Link>

      <Link href="/create-account">
        <PrimaryButton title="Sign up free" />
      </Link>
    </div>
  );
};
