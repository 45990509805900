import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { INavigationDrawerCollapse } from "../../../../../interfaces/globalComponents/layout/material/NavigationDrawer/INavigationDrawerCollapse";
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import {
  setIdFilters,
  setTitleFilters,
} from "../../../../../redux/filtersSlice";
import { IDrawerFiltersItems } from "../../../../../interfaces/globalComponents/layout/material/NavigationDrawer/IDrawerFilters";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

export const NavigationDrawerCollapse = ({
  collapseItems,
  title,
  collapseFilters,
  filterProperty,
  filterFor,
}: INavigationDrawerCollapse) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const dispatch = useDispatch();
  return (
    <ClickAwayListener
      onClickAway={() => {
        setIsOpenDrawer(false);
      }}
    >
      <Stack>
        <ListItemButton
          onClick={() => {
            setIsOpenDrawer((prev) => !prev);
          }}
          sx={{
            backgroundColor: isOpenDrawer
              ? componentColors.secondary.secondaryContainer
              : null,
            "&:hover": {
              backgroundColor: componentColors.secondary.secondaryContainer,
            },
          }}
          className="rounded-3xl "
        >
          <ListItemIcon
            className="mr-[12px]"
            sx={{
              color: componentColors.surface.onSurface,
              minWidth: 0,
            }}
          >
            {isOpenDrawer ? <ArrowDropDown /> : <ArrowRight />}
          </ListItemIcon>

          <Typography
            sx={{
              color: componentColors.surface.onSurface,
              fontFamily: "Roboto",
            }}
            className="font-medium whitespace-nowrap first-letter:uppercase"
          >
            {title}
          </Typography>
        </ListItemButton>

        <Collapse in={isOpenDrawer} timeout={120}>
          <List disablePadding className="pl-[20px]">
            {collapseItems &&
              collapseItems.map((item) => {
                return (
                  <ListItemButton
                    className="rounded-2xl"
                    onClick={() => {
                      if (
                        filterProperty === "sports" ||
                        filterProperty === "objectives"
                      ) {
                        dispatch(
                          setIdFilters({
                            id: item.id,
                            type: filterProperty,
                            filterFor,
                          })
                        );
                      } else if (filterProperty == "interests") {
                        dispatch(
                          setTitleFilters({
                            title: item.value,
                            type: filterProperty,
                            filterFor,
                          })
                        );
                      } else if (filterProperty == "levels") {
                        dispatch(
                          setTitleFilters({
                            title: item.title,
                            type: filterProperty,
                            filterFor,
                          })
                        );
                      }
                    }}
                    key={`${item.id}${item.title}`}
                  >
                    <Typography
                      sx={{
                        color: componentColors.surface.onSurface,
                      }}
                      className="font-medium leading-[20px] not-italic tracking-[0.1px ] whitespace-nowrap"
                    >
                      {item.title}
                    </Typography>
                  </ListItemButton>
                );
              })}

            {collapseFilters &&
              Object.entries(collapseFilters).map(
                (
                  key: [
                    "sports" | "levels" | "objectives" | "interests",
                    IDrawerFiltersItems[]
                  ]
                ) => {
                  return (
                    <Stack key={key[0]} className="mt-[6px]">
                      <NavigationDrawerCollapse
                        title={key[0]}
                        collapseItems={key[1]}
                        filterProperty={key[0]}
                        filterFor={filterFor}
                      />
                    </Stack>
                  );
                }
              )}
          </List>
        </Collapse>
      </Stack>
    </ClickAwayListener>
  );
};
