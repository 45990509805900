import React from "react";
import { NavbarLink } from "./NavbarLink";
import { NavbarLinksWrapper } from "./NavbarlinksWrapper";

export const NavbarLinks = () => {
  return (
    <NavbarLinksWrapper>
      <NavbarLink href="/">For athletes</NavbarLink>
      <NavbarLink href="/coach">For coaches</NavbarLink>
      <NavbarLink href="/explore" className="hidden lg:inline">
        Explore
      </NavbarLink>
    </NavbarLinksWrapper>
  );
};
