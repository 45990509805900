import React from "react";
import SnackbarMaterial from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setShowSnackbar } from "../../../redux/globalSlice";
import { TextButton } from "../buttons/material/textButton/TextButton";

export const Snackbar = () => {
  const globalRedux = useSelector((state: RootState) => state.global);
  const dispatch = useDispatch();
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );

  const handleClose = () => dispatch(setShowSnackbar(false));

  const handleAction = (
    <>
      <TextButton text="Undo" onClick={handleClose} />
    </>
  );

  return (
    <SnackbarMaterial
      onClose={handleClose}
      action={handleAction}
      open={globalRedux.snackbar.showSnackbar}
      message={globalRedux.snackbar.snackbarText}
      autoHideDuration={1500}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: componentColors.surface.surfContainer,
          color: componentColors.background.onBackground,
        },
      }}
    />
  );
};
