import { Paper } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

type NavigationRailWrapperProps = React.ComponentProps<"div">;

export const NavigationRailWrapper = ({
  children,
}: NavigationRailWrapperProps) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Paper
      className="w-14 h-[100vh] fixed p-5 border-2 border-white z-40 items-center flex-col rounded-r-2xl space-y-[24px] hidden lg:flex"
      elevation={5}
      sx={{
        backgroundColor: theme.componentColors.surface.surfaceDim,
        backgroundImage: "none",
      }}
    >
      <div className="flex flex-col h-[90%] items-center justify-between overflow-y-auto w-[60px] scrollbar-hide">
        {children}
      </div>
    </Paper>
  );
};
