import { ThemeProvider } from "@emotion/react";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { createTheme } from "@mui/material";

type MaterialThemeProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export const MaterialThemeProvider = ({
  children,
}: MaterialThemeProviderProps) => {
  const themeRedux = useSelector((state: RootState) => state.theme);

  const theme = {
    breakpoints: {
      values: {
        xs: 599,
        sm: 904,
        md: 1239,
        lg: 1440,
        xl: 2556,
      },
    },
    typography: {
      allVariants: {
        fontFamily: "inherit",
        color: themeRedux.componentColors.surface.onSurface,
      },
    },
    palette: {
      background: {
        default: themeRedux.componentColors.surface.surfContainer,
      },
      mode: themeRedux.mode,
      primary: {
        main: themeRedux.componentColors.primary.primary,
        dark: themeRedux.componentColors.primary.primary,
        light: themeRedux.componentColors.primary.primary,
        contrastText: themeRedux.componentColors.surface.onSurface,
      },
      secondary: {
        main: themeRedux.componentColors.primary.primary,
        dark: themeRedux.componentColors.primary.primary,
        light: themeRedux.componentColors.primary.primary,
        contrastText: themeRedux.componentColors.surface.onSurface,
      },
      error: {
        main: themeRedux.componentColors.error.error,
      },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: themeRedux.componentColors.outline.outline,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            button: {
              textTransform: "none",
            },
          },
          indicator: {
            borderRadius: "99px 99px 0 0",
            height: "0.2rem",
          },
        },
      },
      MuiDateCalendar: {
        styleOverrides: {
          root: {
            ".MuiPickersDay-today": {
              borderColor: themeRedux.componentColors.primary.primary,
              color: themeRedux.componentColors.primary.primary,
            },
            ".MuiPickersYear-yearButton.Mui-selected": {
              color: themeRedux.componentColors.primary.onPrimary,
            },
            ".MuiPickersMonth-monthButton.Mui-selected": {
              color: themeRedux.componentColors.primary.onPrimary,
            },
            ".MuiButtonBase-root.Mui-selected": {
              color: themeRedux.componentColors.primary.onPrimary,
            },
          },
        },
      },
    },
  };

  return <ThemeProvider theme={createTheme(theme)}>{children}</ThemeProvider>;
};
