import { ListingModalLoginButton } from "../../../training-plan/material/listingModal/listingModalLogin/ListingModalLoginButton";
import getConfig from "next/config";
import { IGetConfig } from "../../../../interfaces/next/IGetConfig";
import { Apple } from "@mui/icons-material";
import AppleLogin from "react-apple-login";
import { IListingModalLoginService } from "../../../../interfaces/training-plan/material/listingModal/listingModalLogin/IListingModalLoginService";
import { useLoginWithApple } from "../../../../hooks/user/useLoginWithApple";

const { publicRuntimeConfig } = getConfig() as {
  publicRuntimeConfig: IGetConfig;
};
export const LoginWithAppleButton = ({
  useTitle = true,
  toReload = true,
  setIsLoading,
  loadingButton,
}: IListingModalLoginService) => {
  useLoginWithApple({ setIsLoading, toReload });

  return (
    <AppleLogin
      clientId="com.pod1um.signinservice"
      responseType="code id_token"
      responseMode="form_post"
      scope="name email"
      redirectURI={`${publicRuntimeConfig.NEXT_PUBLIC_BASE_URL}/api/apple`}
      render={(render) => (
        <ListingModalLoginButton
          onClick={render.onClick}
          Icon={Apple}
          title={useTitle ? "Apple" : null}
          disabled={loadingButton}
        />
      )}
    />
  );
};
