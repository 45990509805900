import { List, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { coachInterests } from "../../../../../lib/constants";
import { NavigationDrawerCollapse } from "./NavigationDrawerCollapse";

import {
  AccountCircleOutlined,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import useUser from "../../../../../hooks/user/useUser";
import { NavigationDrawerAction } from "./NavigationDrawerAction";
import Link from "next/link";
import { useLoggedUser } from "../../../../../hooks/user/useLoggedUser";
import { useFilters } from "../../../../../hooks/global/useFilters";

export const NavigationDrawer = () => {
  useLoggedUser();
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );

  const menu = useSelector((state: RootState) => state.menu.value);
  const userRedux = useSelector((state: RootState) => state.user.value);
  const filters = useFilters();
  const { user } = useUser();
  return (
    <Stack
      sx={{ backgroundColor: componentColors.surface.surfContainer }}
      className={`
      ${menu.showDrawer ? "w-[18%] 2xl:w-[15%]" : "w-[0px]"}
       h-[calc(100vh-84px)]  fixed hidden lg:block bottom-0 overflow-auto scrollbar-hide transition-all`}
    >
      <List className="px-[12px] space-y-[9px]">
        {user?.token && userRedux.rendered && (
          <NavigationDrawerAction
            title="Upgrade"
            onClick={() => {
              return;
            }}
            Icon={WorkspacePremiumOutlined}
          />
        )}

        {!user?.token && userRedux.rendered && (
          <Link href="/create-account">
            <NavigationDrawerAction
              title="Sign up"
              onClick={() => {
                return;
              }}
              Icon={AccountCircleOutlined}
            />
          </Link>
        )}
        <Stack>
          <NavigationDrawerCollapse
            title="Find a Coach"
            collapseItems={coachInterests}
            filterProperty="interests"
            filterFor="coaches"
          />
          <NavigationDrawerCollapse
            title="Find a Plan"
            collapseFilters={filters}
            filterFor="listings"
          />
          <NavigationDrawerCollapse
            title="Free Workouts"
            collapseFilters={filters}
            filterFor="workouts"
          />
          <NavigationDrawerCollapse
            title="Blog"
            collapseItems={filters?.objectives}
            filterFor="blogs"
            filterProperty="objectives"
          />
        </Stack>
      </List>
    </Stack>
  );
};
