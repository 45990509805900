import { Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { cn } from "../../../../../helpers/cn";
import { StoreImage, StoreLink } from "../../../MobileStores";

type MaterialFooterGetTheAppProps = React.ComponentProps<"div">;

export const MaterialFooterGetTheApp = ({
  className,
}: MaterialFooterGetTheAppProps) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Stack
      className={cn(
        "flex flex-col items-center  space-y-5 lg:space-y-10",
        className
      )}
    >
      <Typography
        className="text-[20px] font-medium"
        sx={{
          color: theme.componentColors.surface.onSurfaceDetachable,
        }}
      >
        Get the app
      </Typography>

      <div className="flex flex-row justify-center items-center gap-4 md:justify-start lg:flex-col">
        <StoreLink href="https://apps.apple.com/ie/app/pod1um/id1562663063">
          <StoreImage src="/images/apple-white-icon.svg" title="Apple Store" />
        </StoreLink>

        <StoreLink href="https://play.google.com/store/apps/details?id=app.pod1um">
          <StoreImage
            src="/images/google-white-icon.svg"
            title="Google Play Store"
          />
        </StoreLink>
      </div>
    </Stack>
  );
};
