import { fetcher } from "../lib/fetcher";
import { CoachesFromApi } from "../interfaces/specialist/CoachesFromApi";
import * as Sentry from "@sentry/nextjs";
import { IUserData } from "../interfaces/user/IUserData";

export async function getSpecialists(
  query = "",
  page = 0
): Promise<CoachesFromApi> {
  return fetcher(
    `users?role=coach&searchLimit=5&featured=true&searchPage=${page}${query}`
  ).catch((e: Error) => {
    Sentry.captureException({
      category: "coaches",
      message: "error on getSpecialists",
      data: e,
    });
    throw e;
  }) as Promise<CoachesFromApi>;
}

export async function followCoach(coachId: number, token: string) {
  return fetcher(
    `users/${coachId}/following`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    false
  ).catch((e: Error) => {
    Sentry.captureException({
      category: "coaches",
      message: "error on followCoach",
      data: e,
    });
    throw e;
  }) as Promise<{ status: number }>;
}

export async function unfollowCoach(coachId: number, token: string) {
  return fetcher(
    `users/${coachId}/following`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    false
  ).catch((e: Error) => {
    Sentry.captureException({
      category: "coaches",
      message: "error on unfollowCoach",
      data: e,
    });
    throw e;
  }) as Promise<{ status: number }>;
}

export async function getFollowingCoaches(token: string) {
  return fetcher(`users/following`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).catch((e: Error) => {
    Sentry.captureException({
      category: "coaches",
      message: "error on getFollowingCoaches",
      data: e,
    });
    throw e;
  }) as Promise<{ count: number; rows: IUserData[] }>;
}
