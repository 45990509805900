import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

type NavigationLinkTextProps = React.ComponentProps<"p">;

export const NavigationLinkText = ({ children }: NavigationLinkTextProps) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <Typography
      sx={{
        color: colors.surface.onSurface,
      }}
      variant="caption"
      className="text-center"
    >
      {children}
    </Typography>
  );
};
