import { Avatar, ListItem, Stack, Typography } from "@mui/material";
import React from "react";
import { ISearchMenuItem } from "../../../../../interfaces/globalComponents/menu/search/ISearchMenuItem";
import useImageSize from "../../../../../hooks/global/useImageSize";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import Link from "next/link";

export const SearchMenuItem = ({
  title,
  subtitle,
  image,
  link = "",
}: ISearchMenuItem) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  const resizedImage = useImageSize({
    isCover: true,
    size: 300,
    imageUrl: image,
  })[0];
  const avatarText = `${title.split(" ")[0]?.split("")[0]}${
    title.split(" ")[1]?.split("")[0]
  }`;

  return (
    <Link href={link}>
      <ListItem
        className="space-x-[16px] items-center "
        sx={{
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: componentColors.surface.surfContainerHigh,
          },
        }}
      >
        <Avatar src={resizedImage}>{avatarText}</Avatar>
        <Stack>
          <Typography
            sx={{
              color: componentColors.surface.onSurface,
            }}
            className="font-normal text-base"
          >
            {title}
          </Typography>
          <Typography
            sx={{
              color: componentColors.surface.onSurfaceVariant,
            }}
            className="text-sm"
          >
            {subtitle}
          </Typography>
        </Stack>
      </ListItem>
    </Link>
  );
};
