import React from "react";
import { useSearchHistory } from "../../../../../hooks/user/useSearchHistory";
import { SearchMenuItemHistory } from "./SearchMenuItemHistory";
import { List } from "@mui/material";

interface ISearchHistory {
  handleClickHistoryItem: (historyItem: string) => void;
}

export const SearchHistory = ({ handleClickHistoryItem }: ISearchHistory) => {
  const searchHistory = useSearchHistory();

  return (
    <List className="flex flex-col-reverse py-0 ">
      {searchHistory.map((item, i) => {
        return (
          <SearchMenuItemHistory
            key={i}
            item={item}
            onClick={() => handleClickHistoryItem(item)}
          />
        );
      })}
    </List>
  );
};
