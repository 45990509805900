import React from "react";
import { NavbarLogo } from "./NavbarLogo";
import { NavbarWrapper } from "./NavbarWrapper";
import { useDispatch } from "react-redux";
import Link from "next/link";
import { TextButton } from "../../../buttons/material/textButton/TextButton";
import { PrimaryButton } from "../../../buttons/material/primaryButton/PrimaryButton";
import { IconButton } from "../../../buttons/material/iconButton/IconButton";
import { SearchOutlined } from "@mui/icons-material";
import { setShowMobileModal } from "../../../../../redux/searchSlice";
import { NavbarLinksWrapper } from "./NavbarLinks/NavbarlinksWrapper";
import { NavbarLink } from "./NavbarLinks/NavbarLink";
import { SearchMobileModal } from "../Search";

export const NavbarMobile = () => {
  const dispatch = useDispatch();
  const showSearchHandler = () => {
    dispatch(setShowMobileModal(true));
  };

  return (
    <>
      <NavbarWrapper className="flex">
        <SearchMobileModal />
        <NavbarLogo />

        <NavbarLinksWrapper>
          <NavbarLink href="/">For athletes</NavbarLink>
          <NavbarLink href="/coach">For coaches</NavbarLink>
        </NavbarLinksWrapper>

        <div className="flex items-center justify-center">
          <IconButton
            onClick={showSearchHandler}
            icon={<SearchOutlined />}
            className="flex lg:hidden w-12 h-12 "
          />

          <Link href="/login">
            <TextButton text="Log In" className="hidden lg:block" />
          </Link>

          <Link href="/create-account">
            <PrimaryButton title="Sign up free" className="hidden md:block" />
          </Link>
        </div>
      </NavbarWrapper>
    </>
  );
};
