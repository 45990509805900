import { Stack } from "@mui/material";
import { FaLinkedinIn, FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import React from "react";
import {} from "react-icons/fa";
import Link from "next/link";

export const MaterialFooterSocials = () => {
  const socialLinks = [
    {
      Icon: FaFacebookF,
      link: "https://www.facebook.com/pod1um",
    },
    {
      Icon: FaXTwitter,
      link: "https://twitter.com/Pod1um_App",
    },
    {
      Icon: AiFillInstagram,
      link: "https://www.instagram.com/pod1um/",
    },
    {
      Icon: FaLinkedinIn,
      link: "https://www.linkedin.com/company/podiumtechnologiesltd/",
    },
  ];
  return (
    <Stack direction={"row"} className="space-x-[22px]">
      {socialLinks.map(({ Icon, link }, i) => {
        return (
          <Link href={link} key={i}>
            <Icon className="text-[30px]" />
          </Link>
        );
      })}
    </Stack>
  );
};
