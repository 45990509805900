import React from "react";
import { NavigationLinkLoading } from "../NavigationRail/NavigationLinkLoading";

export const BottomAppBarLoading = () => {
  return (
    <div className="flex w-full justify-around">
      <NavigationLinkLoading />
      <NavigationLinkLoading />
      <NavigationLinkLoading />
    </div>
  );
};
