import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { IListingModalLoginButton } from "../../../../../interfaces/training-plan/material/listingModal/listingModalLogin/IListingModalLoginButton";

export const ListingModalLoginButton = ({
  title,
  Icon,
  onClick,
  disabled,
  iconClassName,
}: IListingModalLoginButton) => {
  const theme = useSelector((state: RootState) => state.theme);
  const icon = (
    <Icon className={`text-2xl ${iconClassName ? iconClassName : ""}`} />
  );
  return (
    <Button
      disabled={disabled}
      startIcon={title ? icon : null}
      onClick={() => {
        onClick ? onClick() : null;
      }}
      sx={{
        textTransform: "none",
        backgroundColor: theme.componentColors.surface.onSurfaceDetachable,
        color: theme.mode === "dark" ? "black" : "white",
        "&:hover": {
          backgroundColor: theme.componentColors.surface.onSurfaceDetachable,
          color: theme.mode === "dark" ? "black" : "white",
        },
        "@media(min-width: 1240px)": {
          "&:hover": {
            backgroundColor: theme.componentColors.primary.onPrimary,
            color: theme.componentColors.surface.onSurface,
          },
        },
      }}
      variant="contained"
      className={`${
        disabled ? "opacity-50" : " opacity-100"
      } rounded-[10px] w-full  text-[14px] xl:text-[16px] font-normal p-[8px]`}
    >
      {title ? `Continue with ${title}` : icon}
    </Button>
  );
};
