import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import React from "react";
import { cn } from "../../../helpers/cn";

type Pod1umLogoProps = React.ComponentProps<"img">;

export const Pod1umLogo = ({ className }: Pod1umLogoProps) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <img
      alt="Pod1um Full Logo"
      title="Pod1um Full Logo"
      className={cn("w-full h-full object-contain", className)}
      src={
        theme.mode === "dark"
          ? `/images/pod1um-white-logo.png`
          : `/images/pod1um-black-logo.png`
      }
    />
  );
};
