import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { PersonOutline } from "@mui/icons-material";
import Link from "next/link";

export const SearchBarMobileNotLoggedUser = () => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );

  return (
    <Link href="/create-account">
      <div
        style={{
          backgroundColor: componentColors.primary.primary,
        }}
        className="w-8 h-8 rounded-full flex items-center justify-center"
      >
        <PersonOutline
          sx={{
            color: componentColors.primary.onPrimary,
          }}
        />
      </div>
    </Link>
  );
};
