import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Stack } from "@mui/material";
import { IDefaultLoading } from "../../../interfaces/globalComponents/defaultLoading/IDefaultLoadingComponent";

export const DefaultLoadingComponent = ({
  width,
  height,
  useFullWidth,
  lgHeight,
  rounded,
}: IDefaultLoading) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Stack
      sx={{
        bgcolor: theme.componentColors.surface.surfContainerHighest,
        width: `${width}px`,
        height: `${height}px`,
      }}
      className={`
      ${lgHeight ? lgHeight : ""}
      ${useFullWidth ? "w-full" : ""}
        ${rounded ? rounded : "rounded-2xl"} animate-pulse`}
    />
  );
};
