import {
  AccountCircle,
  AccountCircleOutlined,
  Explore,
  ExploreOutlined,
  Home,
  HomeOutlined,
  WorkspacePremium,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { useLoggedUser } from "../../../../../hooks/user/useLoggedUser";
import { useRouter } from "next/router";
import { BottomAppBarContainer } from "./BottomAppBarContainer";
import {
  NavigationButton,
  NavigationLinkText,
  NavigationLink,
} from "../NavigationRail";

const navigationItems = [
  {
    href: "/",
    icon: HomeOutlined,
    iconActive: Home,
    text: "Home",
  },
  {
    text: "Explore",
    icon: ExploreOutlined,
    iconActive: Explore,
    href: "/explore",
  },
];

export const BottomAppBar = () => {
  useLoggedUser();
  const userRedux = useSelector((state: RootState) => state.user.value);
  const router = useRouter();

  return (
    <BottomAppBarContainer>
      {userRedux.rendered &&
        navigationItems.map((navigationItem, i) => {
          return (
            <NavigationLink key={i} href={navigationItem.href}>
              <NavigationButton
                Icon={navigationItem.icon}
                IconActive={navigationItem.iconActive}
                isActive={router.asPath === navigationItem.href}
              />

              <NavigationLinkText>{navigationItem.text}</NavigationLinkText>
            </NavigationLink>
          );
        })}

      {userRedux.rendered && userRedux.loggedUser && (
        <NavigationLink href={"/payment"}>
          <NavigationButton
            Icon={WorkspacePremiumOutlined}
            IconActive={WorkspacePremium}
            isActive={router.asPath === "/payment"}
          />

          <NavigationLinkText>Upgrade</NavigationLinkText>
        </NavigationLink>
      )}

      {userRedux.rendered && !userRedux.loggedUser && (
        <NavigationLink href={"/create-account"}>
          <NavigationButton
            Icon={AccountCircleOutlined}
            IconActive={AccountCircle}
            isActive={router.asPath === "/create-account"}
          />

          <NavigationLinkText>Log In</NavigationLinkText>
        </NavigationLink>
      )}
    </BottomAppBarContainer>
  );
};
