import { useEffect } from "react";
import { IUserData } from "../../interfaces/user/IUserData";
import { useDispatch } from "react-redux";
import { setLoggedInUser, setSubscription } from "../../redux/userSlice";
import useUser from "./useUser";
import { useRouter } from "next/router";
import { setCookie } from "../../lib/authentication";
import { getCookie } from "../../lib/generic";

export const useLoggedUser = (
  redirectIfLoggedIn?: string,
  userProp?: IUserData
) => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const router = useRouter();

  useEffect(() => {
    if (user.token && redirectIfLoggedIn) {
      void router.push(redirectIfLoggedIn);
    }

    if (user?.token || userProp?.token) {
      dispatch(setLoggedInUser(true));
    } else if (!user?.token || !userProp?.token) {
      dispatch(setLoggedInUser(false));
    }

    if (
      user?.subscription_data &&
      user?.subscription_data.subscriptionPlan.type !== "one2one"
    ) {
      dispatch(setSubscription(user.subscription_data));
    }

    if (!getCookie("pod1umToken") && user?.token) {
      setCookie("pod1umToken", user.token);
    }
  }, [dispatch, user, userProp, redirectIfLoggedIn, router]);
  return true;
};
