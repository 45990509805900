import "@mui/material";
import { Button } from "@mui/material";

import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import Link from "next/link";

export const AuthForgotPasswordButton = () => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Link className="ml-auto" href="/forgot-password">
      <Button
        sx={{
          textTransform: "none",
          color: theme.componentColors.surface.surfaceFull,
        }}
        variant="text"
        className="rounded-2xl px-[16px] text-[14px] font-light"
      >
        Forgot Password?
      </Button>
    </Link>
  );
};
