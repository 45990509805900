import React from "react";

type StoreLinkProps = React.ComponentProps<"a">;

export const StoreLink = ({ href, children }: StoreLinkProps) => {
  return (
    <a href={href} target="_blank">
      {children}
    </a>
  );
};
