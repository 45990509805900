import { IconButton as IconButtonMaterial } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { IIconButton } from "../../../../../interfaces/globalComponents/buttons/material/IIconButton";

export const IconButton = ({
  icon,
  onClick,
  className,
  useBorder = false,
  sx,
}: IIconButton) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );

  return (
    <IconButtonMaterial
      className={className}
      sx={{
        border: useBorder ? "1px solid" : null,
        borderColor: componentColors.outline.outline,
        ...sx,
      }}
      onClick={(e) => {
        if (onClick) {
          void onClick(e);
        }
      }}
    >
      {icon}
    </IconButtonMaterial>
  );
};
