import { ListingModalLoginButton } from "../../../training-plan/material/listingModal/listingModalLogin/ListingModalLoginButton";
import getConfig from "next/config";
import { IGetConfig } from "../../../../interfaces/next/IGetConfig";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FacebookRounded } from "@mui/icons-material";
import { ReactFacebookLoginInfo } from "react-facebook-login";
import * as Sentry from "@sentry/nextjs";
import { loginWithMidias } from "../../../../lib/authentication";
import { useDispatch } from "react-redux";
import { IListingModalLoginService } from "../../../../interfaces/training-plan/material/listingModal/listingModalLogin/IListingModalLoginService";
import { useRouter } from "next/router";
import { sendEmailToFirstPromoter } from "../../../../lib/firstPromoter/sendEmailToFp";
import { setServiceErrorMessage } from "../../../../redux/loginServiceSlice";

const { publicRuntimeConfig } = getConfig() as {
  publicRuntimeConfig: IGetConfig;
};
export const LoginWithFacebookButton = ({
  useTitle = true,
  toReload = true,
  setIsLoading,
  loadingButton,
}: IListingModalLoginService) => {
  const dispatch = useDispatch();
  const router = useRouter();

  function onSignInFacebook(facebookData: ReactFacebookLoginInfo) {
    dispatch(setIsLoading(true));

    if (!facebookData) {
      return;
    }
    loginWithMidias(facebookData.accessToken, "facebook")
      .then((user) => {
        sendEmailToFirstPromoter(user.email);

        if (toReload) {
          void router.reload();
          return;
        }
        void router.push("/");
      })
      .catch((e: Error) => {
        dispatch(setIsLoading(false));
        console.error(e);
        Sentry.captureException({
          category: "LoginWithFacebookButton",
          message: "error on loginWithMidias facebook",
          data: e,
        });

        dispatch(setServiceErrorMessage(e.message));
      });
  }
  return (
    <FacebookLogin
      callback={onSignInFacebook}
      isMobile={false}
      appId={`${publicRuntimeConfig.FACEBOOK_APP_ID}`}
      render={(render) => (
        <ListingModalLoginButton
          onClick={render.onClick}
          Icon={FacebookRounded}
          iconClassName="text-[#0032C2]"
          title={useTitle ? "Facebook" : null}
          disabled={loadingButton}
        />
      )}
    />
  );
};
