import { Fab } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { INavigationDrawerAction } from "../../../../../interfaces/globalComponents/layout/material/NavigationDrawer/INavigationDrawerAction";

export const NavigationDrawerAction = ({
  Icon,
  title,
  onClick,
}: INavigationDrawerAction) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  return (
    <Fab
      variant={"extended"}
      className="h-[56px] rounded-2xl pr-[20px] "
      onClick={() => {
        onClick();
      }}
      sx={{
        textTransform: "none",
        color: componentColors.primary.primary,
        backgroundColor: componentColors.primary.onPrimary,
        "&:hover": {
          color: componentColors.primary.onPrimary,
          backgroundColor: componentColors.primary.primary,
        },
      }}
    >
      <Icon className="mr-[12px]" />
      {title}
    </Fab>
  );
};
