import { Button } from "@mui/material";
import { ITextButton } from "../../../../../interfaces/globalComponents/buttons/material/ITextButton";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

export const TextButton = ({
  text,
  className,
  color,
  onClick,
  startIcon,
  disabled,
}: ITextButton) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Button
      sx={{
        textTransform: "none",
        color: color ? color : theme.componentColors.primary.primary,
      }}
      className={`rounded-3xl px-[24px] h-10 ${className}`}
      variant="text"
      onClick={() => {
        if (onClick) {
          void onClick();
        }
      }}
      startIcon={startIcon}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
