import {
  AppBar,
  ClickAwayListener,
  IconButton,
  InputBase,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { Close } from "@mui/icons-material";
import { useLoggedUser } from "../../../../../hooks/user/useLoggedUser";
import { useEffect, useState } from "react";
import { ListingsFromApi } from "../../../../../interfaces/training-plan/ListingsFromApi";
import { CoachesFromApi } from "../../../../../interfaces/specialist/CoachesFromApi";
import * as Sentry from "@sentry/nextjs";
import { searchByString } from "../../../../../api/search";
import { SearchMenuItem } from "./SearchMenuItem";
import { buildUrlName } from "../../../../../lib/generic";
import { SearchTermItem } from "./SearchTermItem";
import {
  addSearchTerm,
  removeSearchTerm,
} from "../../../../../redux/filtersSlice";
import { useRouter } from "next/router";
import { SearchHistory } from "./SearchHistory";
import { useSearchHistory } from "../../../../../hooks/user/useSearchHistory";

export const SearchBarInput = () => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );
  const [inputValue, setInputValue] = useState("");
  const [debounceValue, setDebounceValue] = useState("");
  const [resultListings, setResultListings] = useState<ListingsFromApi>({
    rows: [],
    count: null,
  });
  const [resultCoaches, setResultCoaches] = useState<CoachesFromApi>({
    rows: [],
    count: null,
  });
  const [resultWorkouts, setResultWorkouts] = useState<ListingsFromApi>({
    rows: [],
    count: null,
  });
  const filters = useSelector((state: RootState) => state.filters);
  const router = useRouter();
  const dispatch = useDispatch();
  const searchHistory = useSearchHistory();
  const [clickedInput, setClickedInput] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const showList =
    (clickedInput && searchHistory.length > 0) ||
    (clickedInput && showSearchResults);

  useLoggedUser();

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebounceValue(inputValue);
    }, 300);

    return () => clearTimeout(timer);
  }, [inputValue]);

  useEffect(() => {
    if (debounceValue) {
      handleInputChange(debounceValue).catch((error: Error) => {
        Sentry.captureException({
          category: "SearchBar",
          message: "error on handleInputChange",
          data: error,
        });
      });
    }
  }, [debounceValue]);

  const handleInputChange = async (searchText: string) => {
    return searchByString(searchText)
      .then((value) => {
        setResultListings(value[0]);
        setResultCoaches(value[1]);
        setResultWorkouts(value[2]);

        setShowSearchResults(
          value[0].rows.length > 0 ||
            value[1].rows.length > 0 ||
            value[2].rows.length > 0
        );
      })
      .catch((error: Error) => {
        Sentry.captureException({
          category: "SearchBar",
          message: "error on handleInputChange",
          data: error,
        });
      });
  };

  const handleClear = () => {
    setInputValue("");
    setDebounceValue("");
    setShowSearchResults(false);

    setResultListings({
      rows: [],
      count: null,
    });
    setResultCoaches({
      rows: [],
      count: null,
    });
    setResultWorkouts({
      rows: [],
      count: null,
    });
  };

  const handleClickHistoryItem = (historyItem: string) => {
    dispatch(addSearchTerm({ searchTerm: historyItem, addInHistory: false }));
    setInputValue("");
    setClickedInput(false);
    setShowSearchResults(false);

    if (router.asPath !== "/explore") {
      void router.push("/explore");
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setClickedInput(false)}>
      <AppBar
        sx={{
          backgroundColor: componentColors.surface.surfContainerLowest,
          color: componentColors.surface.onSurfaceVariant,
          backgroundImage: "none",
        }}
        className={`w-[40rem] h-[46px] lg:h-[56px] ${
          showList ? "rounded-t-[28px]" : "rounded-[28px]"
        } px-[16px] justify-center relative`}
      >
        <Stack
          direction={"row"}
          className="justify-between items-center relative"
        >
          <Stack
            direction={"row"}
            className="flex items-center justify-center space-x-[16px] w-full"
          >
            <SearchIcon />
            {filters.globalSearchTerm?.length > 0 && (
              <SearchTermItem
                onDelete={() => dispatch(removeSearchTerm())}
                label={filters.globalSearchTerm}
              />
            )}
            <InputBase
              onFocus={() => setClickedInput(true)}
              onKeyPress={(event) => {
                if (event.key === "Enter" && inputValue.length > 0) {
                  dispatch(
                    addSearchTerm({
                      searchTerm: inputValue,
                      addInHistory: searchHistory.includes(inputValue.trim())
                        ? false
                        : true,
                    })
                  );
                  setInputValue("");
                  setShowSearchResults(false);
                  setClickedInput(false);

                  if (!router.asPath.includes("explore")) {
                    void router.push("/explore");
                  }
                }
              }}
              value={inputValue}
              sx={{
                "& .MuiInputBase-input::placeholder": {
                  opacity: 1,
                  color: componentColors.surface.onSurfaceVariant,
                },
              }}
              className="w-full "
              placeholder="Search in pod1um"
              onChange={(event) => setInputValue(event.target.value)}
            />
          </Stack>

          <Stack>
            {inputValue.length > 0 && (
              <IconButton
                sx={{
                  color: componentColors.surface.onSurfaceVariant,
                }}
                onClick={handleClear}
              >
                <Close />
              </IconButton>
            )}
          </Stack>
        </Stack>

        {showList && (
          <div
            style={{
              backgroundColor: componentColors.surface.surfContainerLowest,
            }}
            className="absolute  w-full left-0 top-[100%] rounded-b-[28px] max-h-[50vh] overflow-y-scroll"
          >
            <div
              style={{
                borderTop: "1px solid",
                borderTopColor: componentColors.outline.outline,
              }}
              className="scrollbar-hide overflow-y-auto pb-10 border-t"
            >
              <SearchHistory handleClickHistoryItem={handleClickHistoryItem} />

              <>
                {resultListings.rows.map((item) => {
                  return (
                    <SearchMenuItem
                      key={`${item.name} ${item.id}`}
                      title={item.title}
                      image={item.coverImage}
                      subtitle="Training Plan"
                      link={`/training-plan/${item.id}/${buildUrlName(
                        item.title
                      )}`}
                    />
                  );
                })}
              </>

              <>
                {resultCoaches.rows.map((item) => {
                  return (
                    <SearchMenuItem
                      key={`${item.name} ${item.id}`}
                      title={item.name}
                      image={item.avatarUrl}
                      subtitle="Coach"
                      link={`/specialist/${item.id}/${buildUrlName(item.name)}`}
                    />
                  );
                })}
              </>

              <>
                {resultWorkouts.rows.map((item) => {
                  return (
                    <SearchMenuItem
                      key={`${item.name} ${item.id}`}
                      title={item.title}
                      image={item.coverImage}
                      subtitle="Workout"
                      link={`/training-plan/${item.id}/${buildUrlName(
                        item.title
                      )}`}
                    />
                  );
                })}
              </>
            </div>
          </div>
        )}
      </AppBar>
    </ClickAwayListener>
  );
};
