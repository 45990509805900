import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { BottomAppBarLoading } from "./BottomAppBarLoading";

type BottomAppBarContainerProps = React.ComponentProps<"div">;

export const BottomAppBarContainer = ({
  children,
}: BottomAppBarContainerProps) => {
  const userRedux = useSelector((state: RootState) => state.user.value);
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <div
      style={{
        backgroundColor: colors.surface.surfContainerLowest,
      }}
      className="flex items-center justify-around fixed bottom-0 w-screen h-20 pb-2 z-40 lg:hidden"
    >
      {!userRedux.rendered ? <BottomAppBarLoading /> : children}
    </div>
  );
};
