import React from "react";
import { NavbarLogo } from "./NavbarLogo";
import { NavbarWrapper } from "./NavbarWrapper";
import { SearchBarInput } from "../Search/SearchBarInput";
import { useSelector } from "react-redux";
import { ProfileAvatar } from "../avatar/ProfileAvatar";
import { RootState } from "../../../../../redux/store";
import { NavbarLoginButtons } from "./NavbarLoginButtons";
import { NavbarLinks } from "./NavbarLinks/NavbarLinks";
import { useRouter } from "next/router";

export const Navbar = () => {
  const userRedux = useSelector((state: RootState) => state.user.value);
  const router = useRouter();
  const shouldShowLinks =
    !userRedux.loggedUser &&
    (router.pathname === "/" ||
      router.pathname === "/coach" ||
      router.pathname === "/featured-coach" ||
      router.pathname === "/login" ||
      router.pathname === "/create-account" ||
      router.pathname === "/payment");

  return (
    <NavbarWrapper>
      <NavbarLogo />

      {shouldShowLinks ? <NavbarLinks /> : <SearchBarInput />}

      {userRedux.loggedUser ? <ProfileAvatar /> : <NavbarLoginButtons />}
    </NavbarWrapper>
  );
};
