import React from "react";
import { SearchBarMobileWrapper } from "./SearchBarMobileWrapper";
import { SearchBarMobileInput } from "./SearchBarMobileInput";
import { ProfileAvatar } from "../avatar/ProfileAvatar";
import { SearchBarMobileNotLoggedUser } from "./SearchBarMobileNotLoggedUser";
import { useLoggedUser } from "../../../../../hooks/user/useLoggedUser";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

type SearchBarMobileProps = React.ComponentProps<"div">;

export const SearchBarMobile = ({ className }: SearchBarMobileProps) => {
  useLoggedUser();
  const userRedux = useSelector((state: RootState) => state.user.value);

  return (
    <SearchBarMobileWrapper className={className}>
      <SearchBarMobileInput />

      {userRedux.loggedUser ? (
        <ProfileAvatar />
      ) : (
        <SearchBarMobileNotLoggedUser />
      )}
    </SearchBarMobileWrapper>
  );
};
