import { IconButton } from "@mui/material";
import { RootState } from "../../../../../redux/store";
import { useSelector } from "react-redux";

interface NavigationButtonProps extends React.ComponentProps<"button"> {
  IconActive: React.ElementType;
  Icon: React.ElementType;
  isActive: boolean;
}

export const NavigationButton = ({
  Icon,
  IconActive,
  isActive,
}: NavigationButtonProps) => {
  const colors = useSelector((state: RootState) => state.theme.componentColors);

  return (
    <IconButton
      className="rounded-3xl px-[16px] py-[4px]"
      sx={{
        backgroundColor: isActive ? colors.secondary.secondaryContainer : "",
        color: colors.secondary.onSecondaryContainer,
        "&:hover": {
          backgroundColor: isActive ? colors.secondary.secondaryContainer : "",
        },
        "@media (min-width: 1240px)": {
          "&:hover": {
            backgroundColor: colors.secondary.secondaryContainer,
          },
        },
      }}
    >
      {isActive ? (
        <IconActive className="w-[24px] h-[24px]" />
      ) : (
        <Icon className="w-[24px] h-[24px]" />
      )}
    </IconButton>
  );
};
