import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { InputBase } from "@mui/material";
import { SearchTermItem } from "../Search/SearchTermItem";
import {
  setSearchValue,
  setShowMobileModal,
} from "../../../../../redux/searchSlice";
import { removeSearchTerm } from "../../../../../redux/filtersSlice";

export const SearchBarMobileInput = () => {
  const filters = useSelector((state: RootState) => state.filters);
  const dispatch = useDispatch();
  const searchRedux = useSelector(
    (state: RootState) => state.search.searchValue
  );

  return (
    <div className="flex items-center justify-center space-x-[16px] w-full">
      <SearchIcon />

      {filters.globalSearchTerm?.length > 0 && (
        <SearchTermItem
          onDelete={() => dispatch(removeSearchTerm())}
          label={filters.globalSearchTerm}
        />
      )}
      <InputBase
        value={searchRedux}
        onChange={(event) => dispatch(setSearchValue(event.target.value))}
        className="w-full xs:text-[12px] ms:text-[14px] overflow-ellipsis pr-2"
        placeholder="Search Content & Services"
        onClick={() => {
          if (window.innerWidth < 1240) {
            dispatch(setShowMobileModal(true));
          }
        }}
      />
    </div>
  );
};
