import Link from "next/link";
import React from "react";
import { Pod1umFullLogo } from "../../../logo/Pod1umFullLogo";
import { Pod1umLogo } from "../../../logo/Pod1umLogo";

export const NavbarLogo = () => {
  return (
    <Link href="/">
      <Pod1umFullLogo className="hidden md:block lg:w-[220px] lg:h-[43px] 2xl:w-[220px]" />

      <Pod1umLogo className="w-10 h-10 md:w-12 md:w-12 block md:hidden " />
    </Link>
  );
};
