import { LoginWithAppleButton } from "../loginProviderButtons/LoginWithAppleButton";
import { LoginWithFacebookButton } from "../loginProviderButtons/LoginWithFacebookButton";
import { LoginWithGoogleButton } from "../loginProviderButtons/LoginWithGoogleButton";
import { setIsLoadingForm } from "../../../../redux/pages/loginPageSlice";
import { ILoginFormServiceBtns } from "../../../../interfaces/globalComponents/authComponents/login/ILoginFormServiceBtns";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useEffect } from "react";
import { setServiceErrorMessage } from "../../../../redux/loginServiceSlice";
export const LoginFormServiceBtns = ({
  toReload = true,
  isLoadingForm,
}: ILoginFormServiceBtns) => {
  const error = useSelector(
    (state: RootState) => state.loginService.errorMessage
  );
  const colors = useSelector((state: RootState) => state.theme.componentColors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setServiceErrorMessage(""));
  }, [dispatch]);

  return (
    <div>
      {error && (
        <p style={{ color: colors.error.error }} className="text-center">
          {error}
        </p>
      )}

      <div className="grid grid-cols-3 gap-[16px]">
        <LoginWithGoogleButton
          setIsLoading={setIsLoadingForm}
          useTitle={false}
          toReload={toReload}
          loadingButton={isLoadingForm}
        />

        <LoginWithFacebookButton
          setIsLoading={setIsLoadingForm}
          useTitle={false}
          toReload={toReload}
          loadingButton={isLoadingForm}
        />

        <LoginWithAppleButton
          setIsLoading={setIsLoadingForm}
          toReload={toReload}
          useTitle={false}
          loadingButton={isLoadingForm}
        />
      </div>
    </div>
  );
};
