import GoogleLogin, { GoogleLoginResponse } from "react-google-login";
import { ListingModalLoginButton } from "../../../training-plan/material/listingModal/listingModalLogin/ListingModalLoginButton";
import getConfig from "next/config";
import { IGetConfig } from "../../../../interfaces/next/IGetConfig";
import { loginWithMidias } from "../../../../lib/authentication";
import { FcGoogle } from "react-icons/fc";

const { publicRuntimeConfig } = getConfig() as {
  publicRuntimeConfig: IGetConfig;
};
import * as Sentry from "@sentry/nextjs";
import { useDispatch } from "react-redux";
import { IListingModalLoginService } from "../../../../interfaces/training-plan/material/listingModal/listingModalLogin/IListingModalLoginService";
import { useRouter } from "next/router";
import { sendEmailToFirstPromoter } from "../../../../lib/firstPromoter/sendEmailToFp";
import { setServiceErrorMessage } from "../../../../redux/loginServiceSlice";

export const LoginWithGoogleButton = ({
  useTitle = true,
  toReload = true,
  setIsLoading,
  loadingButton,
}: IListingModalLoginService) => {
  const router = useRouter();
  const dispatch = useDispatch();
  function onSignInGoogle(googleUser: GoogleLoginResponse) {
    dispatch(setIsLoading(true));

    if (!googleUser) {
      return;
    }

    loginWithMidias(googleUser.tokenId, "google")
      .then((user) => {
        sendEmailToFirstPromoter(user.email);

        if (toReload) {
          void router.reload();
          return;
        }
        void router.push("/");
      })
      .catch((e: Error) => {
        Sentry.captureException({
          category: "LoginWithGoogleButton",
          message: "error on loginWithMidias google",
          data: e,
        });

        dispatch(setServiceErrorMessage(e.message));
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  }
  return (
    <GoogleLogin
      onSuccess={onSignInGoogle}
      clientId={`${publicRuntimeConfig.GOOGLE_CLIENT_ID}.apps.googleusercontent.com`}
      render={(render) => (
        <ListingModalLoginButton
          onClick={render.onClick}
          Icon={FcGoogle}
          title={useTitle ? "Google" : null}
          disabled={loadingButton}
        />
      )}
    />
  );
};
