import { Avatar } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import useImageSize from "../../../../../hooks/global/useImageSize";
import Link from "next/link";
import useUser from "../../../../../hooks/user/useUser";
import { DefaultLoadingComponent } from "../../../defaultLoading/DefaultLoadingComponent";

export interface IProfileAvatar {
  isLoading?: boolean;
}

export const ProfileAvatar = ({ isLoading = false }: IProfileAvatar) => {
  const { user } = useUser();
  const theme = useSelector((state: RootState) => state.theme);
  const updatedAvatarUrl = useSelector(
    (state: RootState) => state.user.value.avatarUrl
  );
  const resizedImage = useImageSize({
    imageUrl: user.avatarUrl,
    size: 300,
    isProfile: true,
    originalFormat: true,
    updateWithUser: user,
  })[0];

  if (isLoading) {
    return (
      <DefaultLoadingComponent height={40} width={40} rounded="rounded-full" />
    );
  }

  return (
    <Link href="/profile">
      <Avatar
        className="w-[40px] h-[40px] font-semibold cursor-pointer"
        src={updatedAvatarUrl ? updatedAvatarUrl : resizedImage}
        sx={{
          bgcolor: theme.componentColors.primary.onPrimary,
          color: theme.componentColors.surface.onSurface,
        }}
      >
        {user?.name?.split(" ")[0]?.split("")[0]}
        {user?.name?.split(" ")[1]?.split("")[0]}
      </Avatar>
    </Link>
  );
};
