import { Box, Modal } from "@mui/material";
import { IDefaultModal } from "../../../../interfaces/globalComponents/modals/material/IDefaultModal";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export const DefaultModal = ({
  children,
  open,
  onClose,
  showBottomsheetOnlyForMobile = false,
}: IDefaultModal) => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="overflow-hidden"
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: theme.componentColors.primary.onPrimary,
            opacity: "0.4 !important",
          },
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.componentColors.surface.surfContainerHigh,
        }}
        className={`outline-none bottom-0 absolute left-0 
          rounded-b-none sm:h-fit rounded-[28px] rounded-t-[28px]  
         overflow-scroll scrollbar-hide pb-[35px] max-h-[70vh] lg:max-h-[90vh] ${
           showBottomsheetOnlyForMobile
             ? "w-full md:w-auto md:rounded-[28px] md:transform md:-translate-x-1/2 md:-translate-y-1/2 md:left-1/2 md:top-1/2 md:pb-0"
             : "w-full lg:w-auto lg:transform lg:rounded-[28px] lg:-translate-x-1/2 lg:-translate-y-1/2 lg:left-1/2 lg:top-1/2 lg:pb-0"
         }`}
      >
        {children}
      </Box>
    </Modal>
  );
};
