import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

export const NavigationLinkLoading = () => {
  const theme = useSelector((state: RootState) => state.theme);

  return (
    <Stack className="space-y-[10px] items-center justify-center animate-pulse">
      <Stack
        sx={{
          bgcolor: theme.componentColors.surface.surfaceBright,
        }}
        className="rounded-2xl h-[24px] w-[24px]"
      />
      <Stack
        className="w-[30px] h-[10px] rounded-2xl"
        sx={{
          bgcolor: theme.componentColors.surface.surfaceBright,
        }}
      />
    </Stack>
  );
};
