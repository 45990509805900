import { History, NorthWest } from "@mui/icons-material";
import { ListItem, Stack, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { ISearchMenuItemHistory } from "../../../../../interfaces/globalComponents/menu/search/ISearchMenuItemHistory";

export const SearchMenuItemHistory = ({
  item,
  onClick,
}: ISearchMenuItemHistory) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );

  return (
    <ListItem
      sx={{
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: componentColors.surface.surfContainerHigh,
        },
      }}
      onClick={onClick}
      className="space-x-7 h-14 py-2 cursor-pointer items-center"
    >
      <History
        sx={{
          color: componentColors.surface.onSurface,
        }}
      />

      <Stack direction={"row"} className="w-full justify-between items-center">
        <Typography
          sx={{
            color: componentColors.surface.onSurface,
          }}
        >
          {item}
        </Typography>

        <NorthWest
          sx={{
            color: componentColors.surface.onSurface,
          }}
        />
      </Stack>
    </ListItem>
  );
};
