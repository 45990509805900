import { useEffect, useState } from "react";

export const useSearchHistory = () => {
  const [history, setHistory] = useState<string[]>([]);
  const localStorageHistory =
    typeof window !== "undefined" &&
    window?.localStorage.getItem("searchHistory");

  useEffect(() => {
    if (window.localStorage.getItem("searchHistory")) {
      const searchHistoryArray = JSON.parse(
        window.localStorage.getItem("searchHistory")
      ) as string[];

      setHistory(searchHistoryArray);
      return;
    }

    setHistory([]);
  }, [localStorageHistory]);

  return history;
};
