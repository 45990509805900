import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { Chip } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import { ISearchTermItem } from "../../../../../interfaces/globalComponents/menu/search/ISearchTermItem";

export const SearchTermItem = ({ label, onDelete }: ISearchTermItem) => {
  const componentColors = useSelector(
    (state: RootState) => state.theme.componentColors
  );

  return (
    <Chip
      label={label}
      className="max-w-[100px] md:max-w-[200px] lg:max-w-[250px] overflow-ellipsis"
      sx={{
        backgroundColor: componentColors.secondary.secondaryContainer,
        backgroundImage: "none",
        "& .MuiChip-label": {
          fontWeight: 500,
        },
      }}
      icon={
        <div
          style={{
            backgroundColor: componentColors.secondary.onSecondaryContainer,
          }}
          className="rounded-full w-6 h-6"
        >
          <Check
            sx={{
              color: componentColors.surface.surfaceDim,
            }}
          />
        </div>
      }
      onDelete={onDelete}
      deleteIcon={
        <Close
          sx={{
            color: componentColors.surface.onSurface,
          }}
        />
      }
    />
  );
};
